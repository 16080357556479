import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Input, Label, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { BROKER_IMAGES } from "./BrokerImages";
import { withTranslation } from "react-i18next";
import { isDownloadEnabled } from "helpers/config";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { getBroker } from "helpers/bot_trader_api";
import { useDispatch } from "react-redux";
import { updateProfile } from "store/actions";

const StepTasks = ({ t, data, tasks, colWidths, help }) => {

    const dispatch = useDispatch();
    const [taskData, setTaskData] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState({link: null, name: null});

    useEffect(() => {
        setTaskData(tasks);
    }, [tasks]);

    useEffect(() => {
        if (data.brokerId) {
            getBroker(data.brokerId).then(broker => setSelectedBroker(broker));
        }
    }, [data]);

    const onComplete = (taskIndex) => {
        tasks[taskIndex].isComplete = true;
        dispatch(updateProfile({tasks}));
        setTaskData([...tasks]);
    };

    const BotsProperties = createSelector((state: any) => state.bots, (bots) => ({
        bots: bots.bots,
        loading: bots.loading
    }));
    const {
      bots, loading
    } = useSelector(BotsProperties);

    useEffect(() => {
        if (loading) {
            return;
        }
        tasks.forEach((task, index) => {
            if (task.type == "new-robot" && !task.isComplete && bots.length > 0) {
                onComplete(index); // TODO move serer side
            }
            if (task.type == "run-robot" && !task.isComplete) {
                if (bots.filter(r => (r.state == "RUNNING" || r.isDownloaded)).length > 0) {
                    onComplete(index);
                }
            }
        });
    }, [loading]);

    return (

        <React.Fragment>
            <Card>
                <CardBody className="container-fluid">

                    <h4 className="fs-3 card-title mb-3">Tasks</h4>

                    {
                        taskData.map((task, index) => (
                            <Row key={"task-" + index} className="pb-3 pt-3 border-bottom align-items-center">
                                <Col sm="2" className="text-center d-sm-block d-none">
                                    { task.type == "vertex-robots" 
                                        ? <i className="fas fa-robot fs-1"></i> 
                                        : <img style={{width: "70px"}} src={BROKER_IMAGES[task.type]} />
                            
                                    }
                                </Col>
                                <Col xs="7" sm="6">
                                    <h5 className="text-truncate fs-5 mb-1">
                                        {task.title}
                                    </h5>
                                    <p className="text-muted mb-0 fs-4" dangerouslySetInnerHTML={{ __html: task.description }}/> 
                                </Col>

                                <Col xs="2" sm="2">
                                    { (task.action == "broker-link" && selectedBroker.link) && 
                                        <Link to={selectedBroker.link}
                                            target="_blank"
                                            className="btn btn-primary btn-block">
                                            Sign-Up with {selectedBroker.name}
                                        </Link>
                                    }
                                    { task.type == "new-robot" &&
                                        <Link to="/bots/new/choose"
                                            className="btn btn-primary btn-block">
                                            Create Robot
                                        </Link>
                                    }
                                </Col>
                                <Col xs="3" sm="2" className="text-end pt-2">
                                    <div className="d-grid">
                                        
                                        { 
                                            task.isComplete 
                                            ?  <i color="success" className="mdi text-primary fs-1 mdi-check-bold m-auto"/>
                                            : <Button onClick={() => onComplete(index)} className="btn btn-success">Done</Button>
                                        }
                                    </div>

                                </Col>
                            </Row>
                        ))
                    }

                    {isDownloadEnabled && <Row className="m-3">
                        <Alert color="success" className="fs-5" role="alert">
                            {t("Download Alert")}
                        </Alert>
                    </Row>}

                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(StepTasks);