import { 
  UPDATE_PROFILE_ERROR, 
  UPDATE_PROFILE_SUCCESS, 
  UPDATE_PROFILE, 
  RESET_PROFILE_FLAG, 
  GET_PROFILE_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_ERROR,
  UPDATE_SUB_SUCCESS,
  UPDATE_SUB_ERROR,
  UPDATE_SUB,
  GET_SUB,
  GET_SUB_SUCCESS,
  GET_SUB_ERROR
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: true,
  profile: null, 
  subs: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUB:
    case GET_SUB:
    case UPDATE_PROFILE:
    case GET_PROFILE:
      state = { ...state, loading: true };
      break;
    case UPDATE_PROFILE_SUCCESS:
      state = { ...state, profile: action.payload, loading: false };
      break;
    case UPDATE_PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_PROFILE_SUCCESS:
      state = { ...state, profile: action.payload, loading: false };
      break;
    case GET_PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null, loading: false };
      break;
    case GET_SUB_SUCCESS:
      state = { ...state, subs: action.payload, loading: false };
      break;
    case GET_SUB_ERROR:
      state = { ...state, error: action.payload.message, loading: false };
      break;
    case UPDATE_SUB_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case UPDATE_SUB_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
  }
  return state;
};

export default profile;
