import { useEffect } from "react";
import { Collapse } from "reactstrap";


const AdminUserProfileView = ({currentUser, user, show}) => {

    useEffect(() => {
    }, [user]);

    return <>
        {currentUser.claims.admin && 
            <Collapse isOpen={show} className="accordion-collapse">
                <div className="pb-3 ps-3">
                    {user?.uid}<br/>
                    {user?.email}
                </div>
            </Collapse>
        }
    </>

}

export default AdminUserProfileView;