import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { UPDATE_PROFILE, GET_PROFILE, GET_SUB, UPDATE_SUB } from "./actionTypes"
import { updateProfileSuccess, updateProfileError, getProfileSuccess, getProfileError, updateSubSuccess, updateSubError, getSubSuccess, getSubError } from "./actions"

import {
  updateProfile, getProfile, updateSubs, getSubs
} from "helpers/user_profile_api";

function* onGetProfile() {
  try {
    const response = yield call(getProfile)
    yield put(getProfileSuccess(response))      
  } catch (error) {
    yield put(getProfileError(error))
  }
}

function* onGetSubs({ payload: {userId}}) {
  try {
    const response = yield call(getSubs, userId)
    yield put(getSubSuccess(response))      
  } catch (error) {
    yield put(getSubError(error))
  }
}

function* onUpdateSubs({payload: {userId, unsubs}}) {
  try {
    yield call(updateSubs, userId, {unsubs})
    yield put(updateSubSuccess("Subscription settings updated!"))      
  } catch (error) {
    yield put(updateSubError(error))
  }
}

function* onUpdateProfile({payload: {profile}}) {
  try {
    const response = yield call(updateProfile, profile)
    yield put(updateProfileSuccess(response))      
  } catch (error) {
    yield put(updateProfileError(error))
  }
}

function* profileSaga() {
  // yield takeEvery(EDIT_PROFILE, onEditProfile);
  yield takeEvery(GET_PROFILE, onGetProfile);
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile);
  yield takeEvery(UPDATE_SUB, onUpdateSubs);
  yield takeEvery(GET_SUB, onGetSubs);
}

export default profileSaga
